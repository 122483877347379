ion-content.CowsList::part(scroll) {
    /*margin-bottom: 5rem;*/
}

.CowsListHeader {
    display: grid;
    color: #878C9D;
    padding: 0 18px;
    margin-top: 10px;
    grid-template-columns: 1fr 1fr 1fr;
}


.CowsList > div {
    height: 100%;
    display: flex;
    flex-direction: column;
}