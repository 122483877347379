.BookCard {
  padding: 16px;
  display: flex;
  cursor: pointer;
  margin: 12px 8px;
  align-items: center;
  flex-direction: row;
}

.BookCard h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.BookCard h4, .BookCard div {
  flex: 1
}

.BookCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.BookCardField {
  display: flex;
  flex-direction: column;
}

.BookMore {
  margin-left: auto;
}

.BookCardContent {
  display: flex;
  flex-direction: column;
}

.BookCardMore {
  display: flex;
  /*height: 100%;*/
}

.Checkbox {
  margin-right: 12px;
}

ion-item.MoreItem {
  font-size: 16px;
  font-weight: 500;
}

ion-item.DeleteBook {
  font-size: 16px;
  --color: #EB445A;
  font-weight: 500;
}

.MoreOptionsButton {
  padding-inline: 8px;
}