.FormControl {
    width: 100%;
    display: flex;
    padding: 5px 12px;
    padding-left: 20px;
    align-items: center;
    border-bottom: 0.5px solid #c8c7cc;
}

.FormControl label {
    width: 110px;
    text-align: left;
    color: #878C9D !important;
    font-size: 14px !important;;
    font-weight: 400 !important;;
}

.FormControl label span {
    text-align: left;
    margin-left: 3px;
    /*color: #878C9D !important;*/
    font-size: 16px !important;;
    font-weight: bold !important;;
}

.FormControl ion-input {
    color: black;
    font-size: 16px;
    text-align: left;
    font-weight: 500;
}

.FormControl ion-select {
    font-size: 16px;
    text-align: left;
    font-weight: 500;
}

.FormControl ion-label {
    width: 150px;
    color: #878C9D !important;
    font-size: 14px !important;;
    font-weight: 400 !important;;
}

.FormControl ion-select {
    --padding-start: 0
}

.FormControlWrapper ion-item::part(native) {
    padding: 0;
}

.FormControl ion-input label, .FormControl ion-textarea label {
    width: 100%;
}