.TwinForm {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.TwinForm h3 {
    font-size: 16px;
    font-weight: 600;
}

.TwinFormCard {
    margin: 16px;
}

.TwinFormCard .swiper-wrapper {
}

.TwinForm .swiper-pagination .swiper-pagination-bullets .swiper-pagination-horizontal {
    bottom: 0;
}