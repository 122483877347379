.NoteForm {
    height: 100%;
}

.NoteForm ion-card-content {
    padding: 12px;
}

.NoteForm ion-label {
    color: #878C9D !important;
    font-size: 14px !important;;
    font-weight: 400 !important;;
}

.NoteForm ion-item::part(native) {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}