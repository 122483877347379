ion-card-header {
    padding: 16px 16px 0 16px;
}

ion-title {
    padding: 0;
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

ion-card-content {
    gap: 12px;
    display: flex;
    flex-direction: column;
}

ion-card-content ion-label {
    gap: 8px;
    display: flex;
    color: #878C9D;
    font-size: 12px;
    font-weight: 400;
    align-items: center;
}

ion-card-content ion-label span {
    color: #4888AE
}