.CalfForm {
    height: 100%;
}

.CalfForm ion-label {
    color: #878C9D !important;
    font-size: 14px !important;;
    font-weight: 400 !important;;
}

ion-input[disabled] {
    opacity: 1 !important;
    color: #000 !important;
}