ion-content.CalvesList::part(scroll) {
    /*margin-bottom: 5rem;*/
}

.CalvesCount {
    color: #878C9D;
    font-size: 12px;
    font-weight: 400;
}

.CalvesListHeader {
    display: grid;
    color: #878C9D;
    margin-top: 10px;
    margin-inline: 8px;
    padding-inline: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
}

.CalvesList > div {
    height: 100%;
    display: flex;
    flex-direction: column;
}