.Login {
  height: 100%;
  display: flex;
  padding: 70px 32px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.LoginMain {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.LoginMain h3 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 25px;
}

.LoginMain form {
  gap: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.LoginMain ion-button {
  --border-radius: 5px;
}

span {
  display: flex;
  color: #666666;
  font-size: 14px;
  align-items: center;
}

.Logo {
  position: relative;
  margin-bottom: 30px;
}

.Logo h3 {
  margin: 0;
  font-size: 28px;
  font-weight: 600;
}

.Logo ion-badge {
  bottom: 10px;
  right: -35px;
  position: absolute;
}

.Logo span {
  bottom: 30px;
  right: -35px;
  color: #4888ae;
  font-weight: bold;
  padding: 5px 10px;
  position: absolute;
  border-radius: 5px;
  background-color: #95e799;
}

.SignupText {
  margin-bottom: 30px;
}
