.SireCard {
    padding: 8px;
    display: flex;
    cursor: pointer;
    margin: 12px 8px;
    flex-direction: row;
    align-items: center;
}

.SireCard h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.SireCard ion-label {
    font-size: 13px;
    font-weight: 400;
}

.SireCardData {
    display: grid;
    column-gap: 5px;
    grid-template-columns: 1fr 1fr 1fr;
}

.SireCard h4, .SireCard div {
    flex: 1
}

.SireCardContent {
    display: flex;
    flex-direction: column;
}

.SireCardFieldWrapper {
    display: flex;
    justify-content: space-between;
}

.SireCardField {
    display: flex;
    flex-direction: column;
}

.SireMore {
    margin-left: auto;
}