.CowCard {
    padding: 8px;
    display: flex;
    cursor: pointer;
    margin: 12px 8px;
    flex-direction: row;
    align-items: center;
}

.CowCard h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.CowCard h4, .CowCard div {
    flex: 1
}

.CowCardContent {
    display: flex;
    flex-direction: column;
}

.CowCardFieldWrapper {
    display: flex;
    justify-content: space-between;
}

.CowCardField {
    display: flex;
    flex-direction: column;
}

.CowMore {
    margin-left: auto;
}

.CowCardData {
    display: grid;
    column-gap: 5px;
    grid-template-columns: 1fr 1fr 1fr;
}