.NoteCardContent {
    gap: 16px;
    display: flex;
    flex-direction: column;
}

.NoteCardContent > ion-label {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
}

.NoteDate {
    display: flex;
    flex-direction: column;
}

.NoteDate ion-label {
    font-size: 14px;
    font-weight: 400;
}

ion-textarea[disabled] {
    opacity: 1 !important;
}