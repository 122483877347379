.CalfCard {
    padding: 16px;
    display: grid;
    cursor: pointer;
    margin: 12px 8px;
    padding-inline: 8px;
    flex-direction: row;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
}

.CalfCard h4 {
    margin: 0;
}

.CalfCard h4.CalfName {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.CalfCard h4.CowName {
    color: #878C9D;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.CalfCard h4, .CalfCard div {
    flex: 1
}

.CalfCardContent {
    display: flex;
    flex-direction: column;
}

.CalfCardHeader {

}

.CalfCardFieldWrapper {
    display: flex;
    justify-content: space-between;
}

.CalfCardField {
    display: flex;
    flex-direction: column;
}

.CalfMore {
    margin-left: auto;
}