.VaccineCardContent {
    gap: 36px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.VaccineCardField {
    display: flex;
    flex-direction: column;
}

.RemoveIcon {
    margin-left: auto;
}