ion-toolbar {
    --background: white;
}

ion-toolbar.noBorder {
    --border-style: none;
}

.AppHeader {
    height: 56px;
    display: flex;
    align-items: center;
    padding-inline: 20px;
    justify-content: space-between;
}

.AppHeader .Title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.AppHeader .Title span {
    color: #000;
    font-size: 20px;
    font-weight: 500;
}

.AppHeader .Title ion-icon {
    width: 20px;
    height: 20px;
    color: #4888AE;
    margin-right: 32px;
}