.textAlignCenter {
  text-align: center;
}

.Container {
  margin: 0 auto;
  max-width: 1280px;
}

.linkNoDecoration {
  text-decoration: none;
}

ion-content::part(background) {
  background-color: #F5F6F9;
}

ion-tab-button {
  --color-selected: #4888AF;
}